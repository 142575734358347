<div class="lightbox">
  <div class="overlay">
    @if (configSubject | async; as config) {
      <button data-test-lightbox-close class="overlay__close" mat-icon-button (click)="closeLightbox()">
        <mat-icon svgIcon="ak-close" class="large-icon"></mat-icon>
      </button>
      @if (config.showPreviousArrow) {
        <button (click)="prevHandler()" mat-icon-button class="overlay__prev">
          <mat-icon svgIcon="ak-chevron-left"></mat-icon>
        </button>
      }
      @if (config.showNextArrow) {
        <button (click)="nextHandler()" mat-icon-button class="overlay__next">
          <mat-icon svgIcon="ak-chevron-right"></mat-icon>
        </button>
      }
      <div class="overlay__crud-container">
        @if (config.showSaveButton) {
          <button (click)="saveImage()" mat-icon-button>
            <mat-icon svgIcon="ak-download"></mat-icon>
          </button>
        }
        @if (config.showDeleteButton) {
          <button (click)="deleteImage()" mat-icon-button>
            <mat-icon svgIcon="ak-delete"></mat-icon>
          </button>
        }
        @if (config.showEditButton) {
          <button (click)="editImage()" mat-icon-button>
            <mat-icon svgIcon="ak-edit"></mat-icon>
          </button>
        }
      </div>
      @if (isVideo(config.contentType)) {
        <app-video-player
          class="lightbox-video"
          [videoData]="VideoHelpers.lightboxConfigToVideoEntity(config)"
          (videoLoaded)="imageLoaded()"
          (videoLoadFailed)="imageLoadFailed()"
        ></app-video-player>
      }
      @if (!isVideo(config.contentType)) {
        <img
          [hidden]="(stateSubject | async) !== states.IMAGE_LOADED"
          (load)="imageLoaded()"
          (error)="imageLoadFailed()"
          data-test-lightbox-image
          class="lightbox-image"
          [src]="config.imageUrl"
          alt="visible item in the lightbox"
        />
      }
      @if ((stateSubject | async) === states.IMAGE_LOADING && !isVideo(config?.contentType)) {
        <mat-spinner class="overlay__loading"></mat-spinner>
      }
    }
  </div>
</div>
