import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  inject,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VideoHelpers } from '../../../../features/videos/helpers/video.helpers';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AsyncPipe } from '@angular/common';

export interface LightBoxConfig {
  imageUrl: string | null;
  previewUrl: string | null;
  contentType: string | null;
  showNextArrow?: boolean;
  showPreviousArrow?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  showSaveButton?: boolean;

  previousButtonHandler?(): void;

  nextButtonHandler?(): void;

  deleteImageHandler?(index: number): void;

  editImageHandler?(index: number): void;

  saveImageHandler?(index: number): void;
}

export enum State {
  'IMAGE_LOADED' = 'IMAGE_LOADED',
  'IMAGE_LOADING' = 'IMAGE_LOADING',
  'IMAGE_LOAD_FAILED' = 'IMAGE_LOAD_FAILED',
}

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [MatIconButton, MatIcon, VideoPlayerComponent, MatProgressSpinner, AsyncPipe],
})
export class LightboxComponent implements OnInit, OnDestroy {
  renderer = inject(Renderer2);

  configSubject = new BehaviorSubject<LightBoxConfig | null>(null);
  stateSubject = new BehaviorSubject<State>(State.IMAGE_LOADING);

  @Input() set configData(config: LightBoxConfig) {
    if (config.imageUrl && config.imageUrl !== this.configSubject.getValue()?.imageUrl) {
      this.stateSubject.next(State.IMAGE_LOADING);
    }

    this.configSubject.next(config);
  }

  @Output() closeSelected = new EventEmitter();
  @Output() deleteImageEvent = new EventEmitter<any>();
  @Output() editImageEvent = new EventEmitter<any>();
  @Output() saveImageEvent = new EventEmitter<any>();
  @Output() nextButtonClicked = new EventEmitter<any>();
  @Output() prevButtonClicked = new EventEmitter<any>();

  state: State;
  states = State;

  readonly modalBodyClass = 'modal-open';
  protected readonly VideoHelpers = VideoHelpers;

  @HostListener('document:keydown', ['$event'])
  onKeypress(event: KeyboardEvent) {
    if (event.key === 'ArrowRight' || event.key === 'Right') {
      // arrow right
      this.nextHandler();
    } else if (event.key === 'ArrowLeft' || event.key === 'Left') {
      // arrow left
      this.prevHandler();
    } else {
      this.closeLightbox();
    }
  }

  ngOnInit() {
    if (!this.configSubject.getValue()) {
      throw new Error('configData must be provided');
    }

    // this class is used to apply overflow: hidden to the body, prevent the background being scrolled
    this.renderer.addClass(document.body, this.modalBodyClass);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, this.modalBodyClass);
  }

  isVideo(contentType: string | null): boolean {
    return !!contentType?.includes('video');
  }

  closeLightbox() {
    this.closeSelected.emit();
  }

  imageLoaded() {
    this.stateSubject.next(State.IMAGE_LOADED);
  }

  imageLoadFailed() {
    this.stateSubject.next(State.IMAGE_LOAD_FAILED);
  }

  prevHandler() {
    this.prevButtonClicked.emit();
  }

  nextHandler() {
    this.nextButtonClicked.emit();
  }

  deleteImage() {
    this.deleteImageEvent.emit();
  }

  editImage() {
    this.editImageEvent.emit();
  }

  saveImage() {
    this.saveImageEvent.emit();
  }
}
